/* Accordions */

.accordion {
  border: none;
  .accordion-item {
    margin-bottom: .75rem;
    box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);    
    border-radius: .25rem;
    border: 1px solid $border-color;
    .accordion-header {
      background-color: transparent;
      border: none;
      button {
        display: block; 
        width: 100%;
        text-align: left;
        color: inherit;
        text-decoration: none;
        position: relative;
        @include transition(color .5s ease);
        padding-right: 1.5rem;
        line-height: 24px;
        font-weight: 400;
        font-size: 1rem; 
        padding: 1.5rem;
        border: none;
        &:before {
          content: '\e64b';
          font-family:"themify";
          position: absolute;
          right: 24px;
          top: 24;
          font-size: 14px;
          display: block;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
        &:not(.collapsed) {
          box-shadow: none;
          &:before {
            content: "\e648";
            font-family:"themify";
          }
        }
      }
    }
    .accordion-body {
      font-size: 14px;
      padding: 0 2rem 2rem 2rem;
      font-weight: 400;
      line-height: 1.5;
      i {
        font-size: 1.25rem;
      }
    }
  }
  &.accordion-bordered {
    background: $white;
    box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35); 
    border: none;   
    .accordion-item {
      margin: 0 2rem;
      border-top:none;
      box-shadow: none;
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      .accordion-header,
      .accordion-body {
        padding-left: 0;
        padding-right: 0;
      }
      .accordion-header {
        button {
          padding-left: 0;
          padding-right: 0;
          &:before {
            color: $danger;
          }
        }
      }
      &:first-child {
        border-top: 0;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  &.accordion-solid-content {
    .accordion-item {
      padding: 0;
      .accordion-header {
        padding: 0;        
        button {
          padding: 2rem 4.5rem 2rem 2rem;
          @include transition(all .2s linear);
          &:before {
            top: 40%;
            right: 40px;
          }
        }
      }
      .accordion-body {
        padding: 2rem;
        background: $success;
        color: $white;
      }
    }
  }
  &.accordion-multi-colored {
    .accordion-item {
      .accordion-body {
        background: transparent;
        color: $white;
      }
      &:nth-child(1) {
        background: $info;
        .accordion-button {
         &:not(.collapsed) {
          background: $info;
          color: $white;
         }
        }
      }
      &:nth-child(2) {
        background: $success;
        .accordion-button {
          &:not(.collapsed) {
           background: $success;
           color: $white;
          }
         }
      }
      &:nth-child(3) {
        background: $danger;
        .accordion-button {
          &:not(.collapsed) {
           background: $danger;
           color: $white;
          }
         }
      }
      &:nth-child(4) {
        background: $warning;
        .accordion-button {
          &:not(.collapsed) {
           background: $warning;
           color: $white;
          }
         }
      }
      &:nth-child(5) {
        background: $info;
        .accordion-button {
          &:not(.collapsed) {
           background: $info;
           color: $white;
          }
         }
      }
      
    }
  }
}
