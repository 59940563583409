/* Cards */

.card {
  border: none;
  border-radius: $card-border-radius;
  .card-body {
    padding: $card-padding-y $card-padding-x;
    @media(max-width: 767px) {
      padding: 2rem 2rem;
    }
    + .card-body {
      padding-top: 1rem;
    }
  }
  .card-title {
    color: $card-title-color;
    margin-bottom: 1.2rem;
    text-transform: capitalize;
    font-weight: $font-weight-medium;
    font-size: 0.875rem;
  }
  .card-subtitle {
    @extend .text-gray;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  
  }
  .card-description {
    margin-bottom: .875rem;
    font-weight: $font-weight-light;
    color: $card-description-color;
  }
  &.card-outline-success {
		border: 1px solid theme-color("success");
	}
	&.card-outline-primary {
		border: 1px solid theme-color("primary");
	}
	&.card-outline-warning {
		border: 1px solid theme-color("warning");
	}
	&.card-outline-danger {
		border: 1px solid theme-color("danger");
	}

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
  }
  &.card-circle-progress {
    color: $white;
    text-align: center;
  }
  &.card-img-holder {
    position: relative;
    .card-img-absolute {
      position: absolute;
      top:0;
      right: 0;
      height: 100%;
    }
  }
  &.card-rounded {
    border-radius: 15px;
    box-shadow: none;
    border: none;
  }
  &.table-darkBGImg {
    background: #252631 url("../../images/dashboard/darkBG.png") repeat-y right top;
  }
  &.tale-bg {
    background: #DAE7FF;
  }
  &.transparent {
    background: transparent;
  }
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    .card {
      display: inline-block;
      width: 100%;
      margin-bottom: 0.75rem;
    }
  }
}

@each $color, $value in $theme-colors {
  .card-inverse-#{$color} {
    @include card-inverse-variant(rgba($value, .2), theme-color-level($color, 1), theme-color-level($color, 3));
  }
}

@each $color, $value in $theme-colors {
  .data-icon-card-#{$color} {
    background: $value;
    color: $white;
    .card-title {
      color: $white;
    }
    .background-icon {
      @extend .d-flex;
      @extend .justify-content-end;
      &::before{
        content: url('../../images/dashboard/shape-4.svg');
        position: absolute;
      }
      i {
        z-index: 1;
        @extend .icon-lg;
        color: $white;
      }
    }
  }
}
